import React, { useRef, useEffect } from 'react';
import PropTypes from 'prop-types';
import { connect, useDispatch } from 'react-redux';
import { withRouter, useLocation } from 'react-router-dom';
import { Container, Grid, Typography, Box } from '@material-ui/core';

import { Creators as AuthCreators } from '@reducers/Auth';
import { useKeepUiVersionInQueryStringIfPresent } from '@hooks/use-keep-ui-version';
import { useKeepClientIdInQueryStringIfPresent } from '@hooks/use-keep-client-id';

import { SignupBrands } from '@components/index';

import './style.scss';

const LayoutBase = ({
  token,
  children,
  isFullWidth,
  scroll,
  brandError,
  bransisLoading,
  statusCodeBrand,
}) => {
  useKeepUiVersionInQueryStringIfPresent();
  useKeepClientIdInQueryStringIfPresent();
  const ref = useRef(null);
  const dispatch = useDispatch();
  const location = useLocation();

  useEffect(() => {
    if (scroll) {
      dispatch(AuthCreators.resetScrollBottom());
      ref.current.scrollIntoView({ block: 'end', behavior: 'smooth' });
    }
  }, [scroll, ref, dispatch]);

  return (
    <Container
      style={{ padding: 0, maxWidth: '100%', textAlign: 'center' }}
      className={
        (location.pathname === '/404' && 'backgroundGreen') ||
        (location.pathname === '/500' && 'backgroundYellow') ||
        ''
      }
    >
      <Container
        classes={{
          root: 'childrens-container',
        }}
        style={{
          marginBottom: 50,
        }}
      >
        <Grid container item>
          {children}
        </Grid>
      </Container>
      {(location.pathname === '/proveedor' || location.pathname === '/') && (
        <Grid
          component={Box}
          item
          ref={ref}
          display={{ xs: 'flex', md: 'flex' }}
          className="register-container"
        >
          <Grid container spacing={2} alignItems="center">
            <Grid item md={7} xs={12} className="text-center">
              <Typography variant="h4">
                ¡Conquista nuevos clientes y aumenta tus ventas en toda las zonas donde tenemos
                cobertura, en muy poco tiempo!
              </Typography>
            </Grid>
            <Grid item md={4} xs={12} className="text-center">
              {statusCodeBrand !== 200 ? (
                <SignupBrands
                  brandError={brandError}
                  onSubmit={(values) => dispatch(AuthCreators.signUpBransRequest({ ...values }))}
                  bransisLoading={bransisLoading}
                />
              ) : (
                <Grid item xs={12} className="text-center">
                  <Typography variant="h4">¡Perfecto!</Typography>
                  <Typography variant="h5">
                    ¡Un asesor se pondrá en contacto contigo en breve!
                  </Typography>
                </Grid>
              )}
            </Grid>
          </Grid>
        </Grid>
      )}
    </Container>
  );
};

LayoutBase.defaultProps = {
  token: null,
  isFullWidth: false,
  scroll: false,
  bransisLoading: false,
  statusCodeBrand: false,
  brandError: '',
};

LayoutBase.propTypes = {
  children: PropTypes.node.isRequired,
  token: PropTypes.string,
  brandError: PropTypes.string,
  bransisLoading: PropTypes.bool,
  statusCodeBrand: PropTypes.number,
  isFullWidth: PropTypes.bool,
  scroll: PropTypes.bool,
};

const mapStateToProps = ({
  auth: {
    token,
    scroll,
    error: authError,
    isLoading: isAuthLoading,
    statusCodeBrand,
    bransisLoading,
    brandError,
  },
}) => ({
  token,
  authError,
  isAuthLoading,
  brandError,
  bransisLoading,
  statusCodeBrand,
  scroll,
});

export const Layout = connect(mapStateToProps)(withRouter(LayoutBase));
