import { takeLatest, all } from 'redux-saga/effects';
import { Types as AuthTypes } from '@reducers/Auth';

/* ------------- Sagas ------------- */
import { signUpBrans } from './auth/AuthSagas';

/* ------------- Connect Types To Sagas ------------- */
export function* rootSaga() {
  yield all([
    // Auth
    takeLatest(AuthTypes.SIGN_UP_BRANS_REQUEST, signUpBrans),
  ]);
}
