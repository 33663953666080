import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import { createBrowserHistory } from 'history';
import { rootSaga } from '@redux/sagas';
import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { reducer as AuthReducer } from '@reducers/Auth';
import { configureStore } from './configureStore';

export const history = createBrowserHistory();
/* ------------- Assemble The Reducers ------------- */
export const reducers = () =>
  combineReducers({
    auth: AuthReducer,
  });

export const createStore = () => {
  let finalReducers = reducers();
  // If rehydration is on use persistReducer otherwise default combineReducers
  if (reduxPersistConfig.active) {
    const persistConfig = reduxPersistConfig.storeConfig;
    finalReducers = persistReducer(persistConfig, reducers());
  }

  const storeData = configureStore(finalReducers, rootSaga);

  const { store, sagaMiddleware } = storeData;
  let { sagasManager } = storeData;
  if (module.hot) {
    module.hot.accept(() => {
      store.replaceReducer(reducers);

      // eslint-disable-next-line global-require
      const newYieldedSagas = require('../sagas').rootSaga;
      sagasManager.cancel();
      sagasManager.done.then(() => {
        sagasManager = sagaMiddleware(newYieldedSagas);
      });
    });
  }
  return store;
};
