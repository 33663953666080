import * as Yup from 'yup';

const validationSchema = () =>
  Yup.object().shape({
    name: Yup.string().required('Nombre requerido'),
    phone: Yup.string().required('Teléfono requerido'),
    businessName: Yup.string().required('Nombre de marca requerido'),
    website: Yup.string().required('Sitio web o Instagram requerido'),
    email: Yup.string()
      .email('Correo electrónico invalido')
      .required('Correo electrónico requerido'),
    documentNumber: Yup.number().required('RFC requerido'),
  });

export const formikConf = () => ({
  validateOnChange: true,
  validateOnBlur: false,
  initialValues: {
    name: '',
    businessName: '',
    documentNumber: '',
    phone: '',
    verificationDigit: 0,
    email: '',
    website: '',
  },
  validationSchema: validationSchema(),
});

export const onsubmit = (handleSubmit) => (values, formikProps) => {
  formikProps.setSubmitting(false);
  handleSubmit(values);
};
