import React, { Suspense, useEffect } from 'react';
import { connect, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import loadable from '@loadable/component';
import { ThemeProvider, StylesProvider } from '@material-ui/core';
import { SfApp } from '@superfuds/sf-app';
import { HelmetProvider } from 'react-helmet-async';
import { Router, Switch, Route, useLocation, Redirect } from 'react-router-dom';
import { Loader } from '@superfuds/saori';
import mixpanel from 'mixpanel-browser';
import ReactGA from 'react-ga4';
import { v4 as uuidv4 } from 'uuid';

import { Creators as StartupActions } from '@reducers/Startup';

import { reduxPersistConfig } from '@config/reduxPersistConfig';
import { history } from '@redux/store';
import { NODE_ENVIRONMENT, DEVICESESSIONID } from '@constants/index';
import { Error500 } from '@containers/Error500Container/Error500Container';

import { useKeepUiVersionInQueryStringIfPresent } from '@hooks/use-keep-ui-version';
import { useKeepClientIdInQueryStringIfPresent } from '@hooks/use-keep-client-id';
import { UseScrollToTop } from '@hooks/use-windows-top';
import { theme } from './theme';

import '@superfuds/saori/dist/index.css';
import './index.scss';

const Error404 = loadable(() => import('@containers/Error404Container/Error404Container'));
const Provider = loadable(() => import('@containers/ProviderContainer/ProviderContainer'));

const AppBase = ({ user }) => {
  useKeepUiVersionInQueryStringIfPresent();
  useKeepClientIdInQueryStringIfPresent();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!reduxPersistConfig.active) {
      dispatch(StartupActions.startup());
    }
    if (localStorage.getItem(DEVICESESSIONID) === null) {
      localStorage.setItem(DEVICESESSIONID, uuidv4());
    }
    mixpanel.init('20a64c135dfd48703151891eda0f75f5', { debug: false });
    ReactGA.initialize('G-137C6K21P0');
    history.listen((location) => {
      ReactGA.send({ hitType: 'pageview', page: location.pathname });
    });
  }, [dispatch]);

  return (
    <SfApp
      loadingComponent=""
      environment={NODE_ENVIRONMENT}
      user={user}
      history={history}
      enableEvents={false}
      enableDebug={false}
    >
      <Suspense fallback={<Loader />}>
        <ThemeProvider theme={theme}>
          <StylesProvider injectFirst>
            <HelmetProvider>
              <Router history={history}>
                <UseScrollToTop useLocation={useLocation} />
                <Switch>
                  <Route path="/" exact component={Provider} />
                  <Route path="/500" component={Error500} />
                  <Route path="/404" component={Error404} />
                  <Route path="/proveedor" component={Provider} />
                  <Redirect from="*" exact to="/404" />
                </Switch>
              </Router>
            </HelmetProvider>
          </StylesProvider>
        </ThemeProvider>
      </Suspense>
    </SfApp>
  );
};

AppBase.propTypes = {
  user: PropTypes.shape({
    eid: PropTypes.string.isRequired,
  }).isRequired,
};

const mapStateToProps = ({ user }) => ({ user });

export const App = connect(mapStateToProps)(AppBase);
