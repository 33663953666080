import { useEffect } from 'react';

export const UseScrollToTop = ({ useLocation }) => {
  const { pathname } = useLocation();
  useEffect(() => {
    window.scrollTo(0, 0);
  }, [pathname]);

  return null;
};
