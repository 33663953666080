import { put } from 'redux-saga/effects';
import { AuthService } from '@services/auth.service';
import { Creators as AuthCreators } from '@reducers/Auth';

export function* signUpBrans({ payload }) {
  try {
    const user = {
      ...payload,
      client_type: 1,
      lastName: payload.name,
      verification_digit: payload.verificationDigit,
      document: payload.documentNumber.toString(),
      phone: payload.phone.toString(),
      name_contact: payload.name,
      document_type: 4,
      company_name: payload.businessName,
      password: payload.documentNumber.toString(),
      source: 'Contacto Proveedor',
    };
    const { data } = yield AuthService.signup({
      userInfo: user,
      option: 'Brands',
    });
    if (data) {
      yield put(AuthCreators.signUpBransSuccess(payload));
    }
  } catch (error) {
    if (error?.parsedBody?.data) {
      const { message } = error?.parsedBody?.data;
      switch (message) {
        case 'The email already exists':
          yield put(AuthCreators.signUpBransFailure('El correo ya existe'));
          break;
        case 'The document already exists':
          yield put(AuthCreators.signUpBransFailure('El documento ya existe'));
          break;
        case 'Verification digit is invalid':
          yield put(AuthCreators.signUpBransFailure('El dígito de verificación no es válido'));
          break;
        case 'Phone must have minimum 10 digits':
          yield put(
            AuthCreators.signUpBransFailure('El teléfono debe tener un mínimo de 10 dígitos'),
          );
          break;
        case 'The document and phone already exist':
          yield put(AuthCreators.signUpBransFailure('El documento y el teléfono ya existen'));
          break;
        case 'The document already exists with another phone':
          yield put(AuthCreators.signUpBransFailure('El documento ya existe con otro teléfono'));
          break;
        default:
          yield put(AuthCreators.signUpBransFailure('El documento ya existe con otro teléfono'));
          break;
      }
    } else {
      yield put(
        AuthCreators.signUpBransFailure(
          'Actualmente, el sistema no se encuentra disponible intente nuevamente más tarde',
        ),
      );
    }
  }
}
