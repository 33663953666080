// eslint-disable-next-line max-classes-per-file
import { BaseApi } from '@superfuds/sf-base-api';
import { NODE_ENVIRONMENT } from '@constants/env-vars.constants';
import { ODOO_HOST, ZIRO_HOST, DEMO_USER, ODOO_HOST_DEMO } from '@constants/odoo.constants';
import { TOKEN_KEY, TOKEN_ZIRO } from '@constants/config.constants';

export const fetchData = (url, formData) => {
  return fetch(url, {
    method: 'POST',
    body: formData,
  })
    .then((response) => response.json())
    .then((data) => data)
    .catch((error) => error);
};

class CmsApi extends BaseApi {
  constructor() {
    super({
      basePath: 'https://cms.superfuds.co',
      prefix: '',
      environment: NODE_ENVIRONMENT,
      opts: {
        isPublic: true,
      },
    });
  }
}

class OdooApi extends BaseApi {
  constructor() {
    super({
      basePath: ODOO_HOST,
      environment: NODE_ENVIRONMENT,
      prefix: '/api',
      SentryTransaction: true,
      opts: {
        authTokenKey: TOKEN_KEY,
        addPlatform: true,
        addDepartment: true,
      },
      customHeaders: {
        'Content-Type': 'text/plain',
      },
    });
  }

  setBasePath(newBasePath) {
    this.basePath = `${newBasePath}/api`;
  }
}

class ZiroApi extends BaseApi {
  constructor() {
    super({
      basePath: ZIRO_HOST,
      environment: NODE_ENVIRONMENT,
      prefix: '/api',
      SentryTransaction: false,
      opts: {
        addPlatform: false,
        addDepartment: false,
      },
      customHeaders: {
        Authorization: localStorage.getItem(TOKEN_ZIRO),
      },
    });
  }

  setBasePath(newBasePath) {
    this.basePath = `${newBasePath}/api`;
  }
}

let url;
const isDemoUser = localStorage.getItem('DEMO_USER') === DEMO_USER;

if (isDemoUser) {
  url = isDemoUser ? ODOO_HOST_DEMO : ODOO_HOST;
} else {
  url = ODOO_HOST;
}
const restApi = new OdooApi();
restApi.setBasePath(url);
const restApiZiro = new ZiroApi();
restApiZiro.setBasePath(ZIRO_HOST);

export const cmsApi = new CmsApi();
export const odooApi = restApi;
export const ziroApi = restApiZiro;
