import { call } from 'redux-saga/effects';

import { odooApi } from './api';

export const AuthService = {
  *signup({ userInfo, option }) {
    const params = option || 'email';
    return yield call(
      { context: odooApi, fn: odooApi.post },
      '/auth/register',
      {
        option: params,
        platform: 'SFMX',
      },
      userInfo,
    );
  },
};
